@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

body{
  color: var(--main-text-blue);
  
}

:root{
  --main-bg-color : #f0f3f4;
  --main-dark-blue : #154d9c;
  --main-title-blue : #000242;
  --main-text-blue : #585e86;
  --main-link-color : #1ca7e3;
  --main-nav-color : #bad7ff;
  
}

#root {
	overflow-x:hidden;

}

.accentColor{color:#b02e54;}
.mainColor{color:#344559;}
.mainColorDarker{color:#2c3b4c;}

.darkBlue{color :#154d9c;}
.backgroundColor { color: #f0f3f4;}
.titleColor {color: #000242;}
.textColor { color: #585e86; }
.linkColor { color: #1ca7e3 ; }
.navColor {color: #bad7ff !important; }

a{
  color:#ffffff;
  text-decoration: none;
}
a:visited{
  color: #ffffff;
  text-decoration: none;
}

a:hover{
  color: #ffffff !important;
  text-decoration: none !important;;
}

.App {
  text-align: center;
}

.blueColor{
  background-color: var(--main-dark-blue);
}

.rem1{
  font-size: 1rem;
}

.rem08{
  font-size: 0.8rem;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}


.block_blog_image{
  width: 100%;
  height: auto;
  -webkit-border-radius:50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 1s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  opacity: 1;
  z-index: -1;
}


.tutorialBlockContainer{
  background-color: var(--main-bg-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px #2c3b4c;
  -webkit-transition: ease-in 0.3s;
  -moz-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  max-height: 250px;
  min-height: 160px;
}
.background-color-white{
  background-color: var(--main-bg-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px #2c3b4c;
}

.readMoreButton{
  opacity: 0;
  top:-10px;
  position: absolute;
  background: #344559;
  color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-weight: 600;
  padding: 1rem 1rem;
  -webkit-transition: ease-in 0.3s;
  -moz-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.readMoreButton a{
  line-height: 56px;
  cursor: pointer;
}

.tutorialBlockContainer h4, .tutorialBlockContainer p{
  -webkit-transition: ease-in 0.3s;
  -moz-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.tutorialBlockContainer:hover .block_blog_image{
  transform: scale(1.05);
}

.tutorialBlockContainer:hover{
  background-color: rgba(255,255,255,0.6);
}
.tutorialBlockContainer:hover h4, .tutorialBlockContainer:hover p{
  opacity: 0.3;
}
.tutorialBlockContainer:hover .readMoreButton{
  opacity: 1;
  position: absolute;
  z-index: 100;
  top:-10px;
  display: inline-block;
}

.tint {
  width: 87px;
  height: 87px;
  position: absolute;
  top:-45px;
  left:25px;
  -webkit-border-radius:50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
}

.big-picturecontainer{
  font-family: 'Roboto', sans-serif;
  background-image: url('resources/walldesk.jpg');
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;
  background-size: cover;
}
.transition-scroll-down{
  background-color: rgba(0, 2, 66, 1);
}
.fixed-nav {
  position: fixed;
}

.col-centered{
  float: none;
  margin: 0 auto;
}

.navbar{
  font-size: x-large;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
  -webkit-transition: all ease-out .5s;
  -moz-transition: all ease-out .5s;
  -o-transition: all ease-out .5s;
  transition: all ease-out .5s;
}


.appear{
  -webkit-transition: all ease-in .5s;
  -moz-transition: all ease-in .5s;
  -o-transition: all ease-in .5s;
  transition: all ease-in .5s;
}

.invisible{
  display: none;
}

.App-header {
  background-color: #e2e2e2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

 .rem4{
   font-size: 4rem;
   }

 .hoverAccent:hover{
   color:var(--main-dark-blue);
 }

.example{
  width: calc(100% - 9px);
}

.noUnderline:hover{
    color:var(--main-dark-blue);
   text-decoration: none;
 }
.myImage{
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  width: 70%;
  height: 70%;
}
.sizeMedium{
  font-size: 2.5rem;
}

.sizeLarge{
  font-size: 4rem;
}

.sizeLarger{
  font-size: 4rem;
}

.text-align-center{
  text-align: center;
}

.bubble-knowledge{
  min-height: 120px;
  width: 120px;
  border  : 3px solid rgba(186, 215, 255, 1);
  
}

.insideBubble{
  position: relative;
  top:28px;
  transition: all 1.5s;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
}

.bubble-knowledge:hover .insideBubble{
  transform: scale(1.3);
}

.addedScaling{
  transform: scale(1.1);
}



.App-link {
  color: #61dafb;
}

.RoundCircle{
  height: 46px;
  width: 46px;
  background-color: rgba(28, 167, 227, 0.6);
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.innerCircle{
  top: 50%;
  left: 50%;
  margin: 8px 0px 0px 8px;
  height: 30px;
  width: 30px;
  background-color: var(--main-dark-blue);
  border-radius: 50%;
  display: inline-block;
}

.dateOfEducation{
  font-size:2rem;
  font-weight:400;
}

.dateOfEducation2{
  font-size:1.5rem;
  font-weight:400;
}

.lineBottom{
  position: absolute;
  left: 22.75%;
  width: 6px;
  height: 100%;
  top:5%;
  background: var(--main-dark-blue);
}

.mts-5{
  margin-top:3rem !important;
}

.pts-5{
  padding-top: 3rem !important;
}

.overlay_phone{
  width: 95.8%;
  height: auto;
  position: absolute;
  top:-28px;
  left:9px;
  z-index: 100;
}

.phone_size{
  width: 100%;
  height: auto;
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.icon_modal{
  width: 180px;
  height: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.icon_modal:hover{
  cursor: pointer ;
  transform: scale(1.02);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1199px) {
  .lineBottom{
    position: absolute;
    left: 23.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }


  .phone_size{
    width: 100%;
    height: auto;
  }


  .overlay_phone{
    width: 91.6%;
    height: auto;
    position: absolute;
    top:-19px;
    left:9px;
    z-index: 100;
  }
}

@media only screen and (max-width: 1024px) {
  .lineBottom{
    position: absolute;
    left: 23.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }
}


@media only screen and (max-width: 991px) {
  .lineBottom{
    position: absolute;
    left: 25.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }

  .dateOfEducation{
    font-size: 1.5rem;
  }

  .mobile-square{
    -webkit-border-radius: 0% !important;
    -moz-border-radius: 0% !important;
    border-radius: 0% !important;
    margin-bottom:10px;
    width: 100%;
  }

  .phone_size{
    width: 100px;
    height: auto;
  }


  .overlay_phone{
    width: 104px;
    height: auto;
    position: absolute;
    top:-10px;
    left:11px;
    z-index: 100;
  }


}


@media only screen and (max-width: 768px) {
  .lineBottom{
    position: absolute;
    left: 32.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }

  .dateOfEducation{
    font-size: 1.5rem;
  }

  .mobile-padding-left{
    padding-left:30px;
  }
  .mobile-square{
    -webkit-border-radius: 0% !important;
    -moz-border-radius: 0% !important;
    border-radius: 0% !important;
    margin-bottom:10px;
    width: 100%;
  }

  .rem4{
    font-size: 3rem;
  }
  .rem3{
    font-size: 1.5rem;
  }

  .phone_size{
    width: 100px;
    height: auto;
  }


  .overlay_phone{
    width: 105px;
    height: auto;
    position: absolute;
    top:-11px;
    left:11px;
    z-index: 100;
  }

}
@media only screen and (max-width: 767px) {
  .lineBottom {
    position: absolute;
    left: 32.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }
  .dateOfEducation{
    font-size: 1.5rem;
  }

  .lineBottom{
    position: absolute;
    display: none;
    left: 25.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }

  .RoundCircle{
    display: none;
  }

  .phone_size{
    width: 462px;
    height: auto;
  }


  .overlay_phone{
    width: 480px;
    height: auto;
    position: absolute;
    top:-44px;
    left:9px;
    z-index: 100;
  }

  
}

@media only screen and (max-width:575px){

  .phone_size{
    width: 97.5%;
    height: auto;
  }


  .overlay_phone{
    width: 97.5%;
    height: auto;
    position: absolute;
    top:-5.3%;
    left:0.8%;
    z-index: 100;
  }

}


@media only screen and (max-width:496px){
  .phone_size{
    width: 97%;
    height: auto;
  }


  .overlay_phone{
    width: 96.5%;
    height: auto;
    position: absolute;
    top:-5.3%;
    left:0.8%;
    z-index: 100;
  }
}


@media only screen and (max-width:426px){
  .phone_size{
    width: 100%;
    height: auto;
  }


  .overlay_phone{
    width: 98.5%;
    height: auto;
    position: absolute;
    top:-5.3%;
    left:0.8%;
    z-index: 100;
  }
}



@media only screen and (max-width: 425px) {
  .lineBottom{
    position: absolute;
    display: none;
    left: 25.75%;
    width: 6px;
    height: 100%;
    background: var(--main-dark-blue);
  }


  .RoundCircle{
    display: none;
  }

  .dateOfEducation{
    font-size: 1.5rem;
  }

  .mts-5{
    margin-top:1rem !important;
  }

  .pts-5{
    padding-top: 1rem !important;
  }

  .mobile-square{
    -webkit-border-radius: 0% !important;
    -moz-border-radius: 0% !important;
    border-radius: 0% !important;
    margin-bottom:10px;
    width: 100%;
  }

  .phone_size{
    width: 100%;
    height: auto;
  }


  .overlay_phone{
    width: 98.5%;
    height: auto;
    position: absolute;
    top:-5.3%;
    left:0.8%;
    z-index: 100;
  }

}

  @media only screen and (max-width:415px){
    .phone_size{
      width: 97%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 96%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:0.8%;
      z-index: 100;
    }
  }



  @media only screen and (max-width:412px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 98%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:0.8%;
      z-index: 100;
    }
  }


    
  @media only screen and (max-width:376px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 98%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:1.5%;
      z-index: 100;
    }
  }

  @media only screen and (max-width:375px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 93.8%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:1.5%;
      z-index: 100;
    }
  }




  @media only screen and (max-width:360px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 97.5%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:1.5%;
      z-index: 100;
    }
  }


  
  @media only screen and (max-width:358px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 97.5%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:0.8%;
      z-index: 100;
    }
  }


  @media only screen and (max-width:320px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 96.5%;
      height: auto;
      position: absolute;
      top:-5.3%;
      left:2%;
      z-index: 100;
    }
  }

  @media only screen and (max-width:316px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 96.5%;
      height: auto;
      position: absolute;
      top:-5.4%;
      left:2%;
      z-index: 100;
    }
  }

  
  @media only screen and (max-width:260px){
    .phone_size{
      width: 100%;
      height: auto;
    }
  
  
    .overlay_phone{
      width: 94.8%;
      height: auto;
      position: absolute;
      top:-5.4%;
      left:2%;
      z-index: 100;
    }
  }
  
